import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MainPage from "../pages/MainPage/MainPage";
import routes from "../routes/routes";
import lodashDebounce from "lodash.debounce";
import MobileAndTabletStub from "../components/MobileAndTabletStub/MobileAndTabletStub";

class App extends Component {
  state = {
    // ширина экрана пользователя
    clientWidth: window.innerWidth, // минимальное 900
    clientHeight: window.innerHeight, // минимальное 600
  };

  componentDidMount() {
    window.addEventListener(
      "resize",
      lodashDebounce(this.updateDimensions, 200)
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      clientWidth: window.innerWidth,
      clientHeight: window.innerHeight,
    });
  };

  render() {
    const { clientWidth, clientHeight } = this.state;

    // ЕСЛИ ЧЕЛОВЕК зашел с устройства с экраном меньше 900pх и высота 600рх то не показывать ему ничего кроме заглушки:
    if (clientWidth < 900 || clientHeight < 600) {
      return <MobileAndTabletStub />;
    }

    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={routes.MAIN_PAGE.path}
            render={(routeProps) => (
              <MainPage
                {...routeProps}
                clientWidth={clientWidth}
                clientHeight={clientHeight}
              />
            )}
          />
          <Route
            path={routes.RESULT_PAGE.path}
            component={routes.RESULT_PAGE.component}
          />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
