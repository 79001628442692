import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./CongratsModal.module.css";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import { ReactComponent as WinnerCup } from "../../assets/icons/cup.svg";
// import cup from "../../assets/images/award.png";

// const defaultVideoLink =
//   "https://www.youtube.com/embed/wrw0UCU66GE?list=PLViULGko0FdhfDVrVR1q02egcPGfo3TDr";

const CongratsModal = ({
  onClose,
  blockTasksArr,
  blockIndex,
  getTotalProgress,
}) => {
  const tasksCount = blockTasksArr.length;
  return (
    <ModalWrapper
      onClose={() => {
        onClose();
        getTotalProgress();
      }}
      hideCloseButton
    >
      <section className={s.wrapper}>
        {/* <img className={s.winnerCup} src={cup} alt="award" /> */}
        <WinnerCup className={s.winnerCup} width="118" />
        <h2 className={s.modalTitle}>ОТЛИЧНАЯ РАБОТА!</h2>
        <Close
          className={s.close}
          onClick={() => {
            onClose();
            getTotalProgress();
          }}
        />
        <div className={s.contentWrapper}>
          <p className={s.text}>
            Дорогой марафонец! Ты успешно прошел {blockIndex}-й день марафона!
            Твои результаты на сегодня:
          </p>
          {/* <p className={s.text}>
            Ты успешно прошел {blockIndex}-й день марафона!
          </p> */}

          {/* <h2 className={s.resultTitle}>Твои результаты на сегодня: </h2> */}
          <p className={s.result}>
            <span className={s.countTasks}>{tasksCount}</span>
            успешных ответов
          </p>

          {blockIndex !== 5 && (
            <a
              className={s.buttonResult}
              href={`https://gomarathon.com.ua/result?day=${blockIndex}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Визуально оценить результат работы можешь тут
            </a>
          )}

          <p className={s.text}>
            Ты отлично поработал сегодня!&ensp;
            {blockIndex !== 5 && (
              <>
                Теперь хорошенько отдохни и набирайся сил.
                <span className={s.fatText}>Ждем тебя завтра!</span>
              </>
            )}
          </p>

          <div className={s.buttonContainer}>
            {blockIndex === 1 && (
              <a
                onClick={() => {
                  onClose();
                  getTotalProgress();
                }}
                href="https://www.youtube.com/watch?v=7pMOogr1h00&feature=youtu.be"
                target="_blank"
                rel="noopener noreferrer"
                className={s.button}
              >
                Хочу бонусные видео
              </a>
            )}
            {blockIndex === 2 && (
              <a
                onClick={() => {
                  onClose();
                  getTotalProgress();
                }}
                href="https://www.youtube.com/watch?v=TfU3EAMqVOA&feature=youtu.be"
                target="_blank"
                rel="noopener noreferrer"
                className={s.button}
              >
                Хочу бонусные видео
              </a>
            )}
            {/* это пока заглушка, пока нет плейлистов на каждый из дней  */}
            {(blockIndex === 3 || blockIndex === 4 || blockIndex === 5) && (
              <a
                onClick={() => {
                  onClose();
                  getTotalProgress();
                }}
                href="https://www.youtube.com/playlist?list=PLViULGko0FdgoSiqf5BMgmiAWBicyiwJy"
                target="_blank"
                rel="noopener noreferrer"
                className={s.button}
              >
                Хочу бонусные видео
              </a>
            )}
          </div>
        </div>
      </section>
    </ModalWrapper>
  );
};

export default CongratsModal;
