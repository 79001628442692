import React, { Component } from "react";
import s from "./ResultPage.module.css";
import HeaderResultPage from "../../components/HeaderResultPage/HeaderResultPage";
import FooterResultPage from "../../components/FooterResultPage/FooterResultPage";

import CvOriginal from "../../assets/images/resultPageImages/cv_original.jpg";

class ResultPage extends Component {
  state = {};

  render() {
    return (
      <>
        <HeaderResultPage />
        <article className={s.container}>
          <header className={s.header}>
            <section className={s.headerRigthSide}>
              <h2 className={s.headerTitle}>Твой результат</h2>
              {/* <p>
                Сегодня в 4-й день марафона ты добавил css правила (к уже
                имеющейся html разметке) для левой части резюме
              </p> */}
            </section>
            <section className={s.headerLeftSide}>
              <h2 className={s.headerTitle}>Макет</h2>
              {/* <p>
                Цель на сегодня - добавить css правила (к уже имеющейся html
                разметке) для левой части резюме
              </p> */}
            </section>
          </header>

          <div className={s.imageContainer}>
            {/* <img className={s.imgcv} src={Day4} alt="result" /> */}

            <img className={s.imgcv} src={CvOriginal} alt="your result" />

            <img className={s.imgcv} src={CvOriginal} alt="cv" />
          </div>
        </article>
        <p className={s.text}>
          Поздравляем с успешным завершением HTML/CSS пре-курса. Сертификат о
          прохождении пре-курса уже отправлен тебе нашим telegram ботом.
        </p>
        <p className={s.text2}>
          Также рекомендуем подписаться на наш{" "}
          <a
            className={s.link}
            href="https://t.me/junior_developer_ua"
            target="_blank"
            rel="noopener noreferrer"
          >
            telegram-канал «junior developer»
          </a>
          . В нем публикуются полезные для новичков статьи и обзоры технических
          инструментов.
        </p>
        <FooterResultPage />
      </>
    );
  }
}

export default ResultPage;
