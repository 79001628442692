import React, { Component } from "react";
import styles from "./TaskButtons.module.css";
import { ReactComponent as Refresh } from "../../assets/icons/autorenew-black-18dp.svg";
import { ReactComponent as Movie } from "../../assets/icons/movie-black-18dp.svg";
import Timer from "../Timer/Timer";
import VideoModal from "../VideoModal/VideoModal";
import ModalRefreshQuestion from "../ModalRefreshQuestion/ModalRefreshQuestion";

class TaskButtons extends Component {
  state = {
    showPreviewModal: false,
    showPreviewModalFirst: false,
    firstTime: true, // поле которое отвечает за то чтобы только один раз срабатывала анимация,
    showVideoModal: false,
    showModalRefreshQuestion: false,
  };

  showVideoModal = () => {
    const { showTimer } = this.props;
    if (!showTimer) {
      this.setState({ showVideoModal: true });
    }
  };
  closeVideoModal = () => this.setState({ showVideoModal: false });

  changeButtonLayout = (startTaskId) => {
    const { currentTaskId } = this.props;
    if (startTaskId !== currentTaskId) return;
    document.querySelector("#layout-button").classList.add("enterButtonLayout");
    setTimeout(() => {
      document
        .querySelector("#layout-button")
        .classList.add("exitButtonLayout");

      setTimeout(() => {
        document
          .querySelector("#layout-button")
          .classList.remove("enterButtonLayout");
        document
          .querySelector("#layout-button")
          .classList.remove("exitButtonLayout");
      }, 3000);
    }, 3000);
  };

  openModalRefreshQuestion = () =>
    this.setState({ showModalRefreshQuestion: true });

  closeModalRefreshQuestion = () =>
    this.setState({ showModalRefreshQuestion: false });

  openNextQuestion = () => {
    const {
      blockTasksArr,
      currentTaskId,
      getNextTask,
      checkTest,
      percentageDoneTask,
      openModalBlockResultAndChangeCount,
      showModalBlockResult,
    } = this.props;
    getNextTask();
    checkTest();
    const questionNumber = blockTasksArr.indexOf(currentTaskId) + 1;
    if (
      !showModalBlockResult &&
      percentageDoneTask === 100 &&
      (questionNumber === 10 ||
        questionNumber === 15 ||
        questionNumber === 32 ||
        questionNumber === 44)
    ) {
      openModalBlockResultAndChangeCount();
    }
  };

  render() {
    const {
      checkTest,
      refreshCurrentQuestion,
      timerEndTime,
      metadata,
      showTimer,
      hideTimer,
      percentageDoneTask,
      clientWidth,
      // isTheoryAndTaskShow,
      currentTaskId,
      blockTasksArr,
      getTotalProgress,
    } = this.props;

    const {
      // showPreviewModal,
      // showPreviewModalFirst,
      showVideoModal,
      showModalRefreshQuestion,
    } = this.state;

    const isLastQuestion =
      blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1;
    return (
      <div className={styles.buttonsContainer}>
        {!isLastQuestion && (
          <button
            className={
              percentageDoneTask === 100
                ? styles.checkButton
                : styles.disableCheckButton
            }
            onClick={this.openNextQuestion}
          >
            Следующий вопрос
          </button>
        )}

        {isLastQuestion && (
          <button
            className={
              percentageDoneTask === 100
                ? styles.checkButton
                : styles.disableCheckButton
            }
            onClick={() => {
              checkTest();
              getTotalProgress();
            }}
          >
            Завершить
          </button>
        )}

        <button
          onClick={this.openModalRefreshQuestion}
          className={styles.buttonWithSvg}
        >
          <Refresh className={styles.svg} />
          Сбросить
        </button>
        {(clientWidth > 1600 || !showTimer) && (
          <button
            onClick={this.showVideoModal}
            className={
              !showTimer ? styles.buttonWithSvg : styles.disableButtonWithSvg
            }
          >
            <Movie className={!showTimer ? styles.svg : styles.disableSvg} />
            Видеоподсказка
          </button>
        )}

        {showTimer && (
          <Timer timerEndTime={timerEndTime} hideTimer={hideTimer} />
        )}

        {showVideoModal && (
          <VideoModal metadata={metadata} onClose={this.closeVideoModal} />
        )}

        {showModalRefreshQuestion && (
          <ModalRefreshQuestion
            onClose={this.closeModalRefreshQuestion}
            refreshCurrentQuestion={refreshCurrentQuestion}
          />
        )}
      </div>
    );
  }
}

export default TaskButtons;
