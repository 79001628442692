import React, { useState } from "react";
import styles from "./Header.module.css";
import SelectCurrentQuestion from "../SelectCurrentQuestion/SelectCurrentQuestion";
import { ReactComponent as Logo } from "../../assets/icons/logo/goit_logo_small.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/arrow/right-arrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/arrow/left-arrow.svg";

const Header = ({
  blockTasksArr,
  currentTaskId,
  getPrevTask,
  getNextTask,
  passed,

  passedTasks,
  getSpecificTask,
  headerTextLogoRef,
  headerTextQuestionRef,
}) => {
  const [isOpenSelect, setSelect] = useState(false);
  const openSelect = () => setSelect(true);
  const closeSelect = () => setTimeout(() => setSelect(false), 0);

  const blockQuestionQuantity = blockTasksArr.length;
  const numberCurrentQuestion = blockTasksArr.indexOf(currentTaskId) + 1;
  const showPrevButton =
    blockTasksArr.indexOf(currentTaskId) === 0 ? false : true;
  const showNextButton =
    blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1 || !passed
      ? false
      : true;
  return (
    <header className={styles.header_container}>
      <div className={styles.headerContent}>
        <h1 className={styles.logo_container}>
          <a href="https://goit.ua/" target="_blank" rel="noopener noreferrer">
            <Logo className={styles.img} />
          </a>
          <span className={styles.logo_title} ref={headerTextLogoRef}>
            Пре-курс HTML/CSS
          </span>
        </h1>

        <div className={styles.questionCounter_container}>
          {showPrevButton && (
            <button onClick={getPrevTask} className={styles.button}>
              <LeftArrow className={styles.arrow} />
            </button>
          )}

          <div
            className={styles.count}
            onClick={openSelect}
            ref={headerTextQuestionRef}
          >
            Задача {numberCurrentQuestion} / {blockQuestionQuantity}
            {isOpenSelect && (
              <SelectCurrentQuestion
                closeSelect={closeSelect}
                passedTasks={passedTasks}
                getSpecificTask={getSpecificTask}
                blockTasksArr={blockTasksArr}
              />
            )}
          </div>

          {showNextButton && (
            <button onClick={getNextTask} className={styles.button}>
              <RightArrow className={styles.arrow} />
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
