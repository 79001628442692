import React from "react";
import { Resizable } from "re-resizable";

const style = {
  width: "35%",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // border: "solid 1px #ddd",
  //   background: "#f7f7f7",
  backgroundColor: "rgba(193, 193, 193, 0.5)",
  paddingLeft: "1%",
  //   boxSizing: "border-box",
};

const ResizableWrapper = ({ children }) => (
  <Resizable
    style={style}
    defaultSize={{
      width: "40%",
      height: "38vh",
    }}
    minWidth="30%"
    maxWidth="70%"
    // maxHeight="50vh"
    // minHeight="50vh"
    // lockAspectRatio="true"
    // bounds="parent"
    enable={{
      top: false,
      right: false,
      bottom: false,
      left: true,
      topRight: false,
      bottomRight: false,
      bottomLeft: false,
      topLeft: false,
    }}
  >
    {children}
  </Resizable>
);

export default ResizableWrapper;
