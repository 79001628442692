import React, { Component, createRef } from "react";
// компоненты из библиотек:
import { ToastContainer, toast } from "react-toastify";
// import { Controlled as CodeMirror } from "react-codemirror2";
// import codeMirrorCssSettings from "../../utils/codeMirrorCssSettings";
// import CodeMirror from "codemirror";
import CustomCodeMirror from "../../components/CustomCodeMirror/CustomCodeMirror";
// cтили:
import styles from "./MainPage.module.css";
import "react-toastify/dist/ReactToastify.css";
// компоненты созданные:
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ButtonHideTheory from "../../components/ButtonHideTheory/ButtonHideTheory";
import TheoryAndTaskOrLayout from "../../components/TheoryAndTaskOrLayout/TheoryAndTaskOrLayout";
import FrameComponent from "../../components/FrameComponent/FrameComponent";
import TaskButtons from "../../components/TaskButtons/TaskButtons";
import ResultModal from "../../components/ResultModal/ResultModal";
import CongratsModal from "../../components/CongratsModal/CongratsModal";
import ErrorStub from "../../components/ErrorStub/ErrorStub";
import ErrorInvalidTokenOrBlock from "../../components/ErrorInvalidTokenOrBlock/ErrorInvalidTokenOrBlock";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import BrowserResultModal from "../../components/BrowserResultModal/BrowserResultModal";
import ErrorServerStub from "../../components/ErrorServerStub/ErrorServerStub";
import ErrorServerRefreshStub from "../../components/ErrorServerRefreshStub/ErrorServerRefreshStub";
import ModalAutotranslator from "../../components/ModalAutotranslator/ModalAutotranslator";
// import ModalUnresolvedDays from "../../components/ModalUnresolvedDays/ModalUnresolvedDays";
import Loader from "../../components/Loader/Loader";
import ModalFinish from "../../components/ModalFinish/ModalFinish";
// прочие вспомогательные инструменты:
import * as API from "../../services/api";
// стили для codemirror
import "codemirror/lib/codemirror.css";
import "../../utils/materialCodeMirror.css";
import "codemirror/theme/neat.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/htmlmixed/htmlmixed";
import { ReactComponent as Done } from "../../assets/icons/done.svg";
import { ReactComponent as NotDone } from "../../assets/icons/notDone.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ResizableWrapper from "../../components/ResizableWrapper/ResizableWrapper";
import ModalBlockResult from "../../components/ModalBlockResult/ModalBlockResult";

const oneMinuteInMs = 60000;
// const oneMinuteInMs = 60;

let shouldFastCheckTask = false;

let shouldAvailabilityAutotranslator = false;
const waitTimeInMs = 1000;
let availabilityAutotranslatorTimer = waitTimeInMs;
let timerAvailabilityAutotranslatorId;

class MainPage extends Component {
  state = {
    currentTaskId: "",
    block: null,
    blockTasksArr: "",
    token: "",
    valueHTML: "",
    valueCSS: "",
    htmlDescription: "",
    metadata: "",
    passed: "", // выполнена ли текущая задача или нет
    blockIndex: 0, // это номер дня марафоне в хедере и в поздравительной модалке
    // для модального окна с результатами:
    isResultModalOpen: false,
    successConditions: null,
    failedConditions: null,
    taskSuccessfullyPassed: null,
    // появление пропадание теории с заданием:
    isTheoryAndTaskShow: true,
    // модальное окно с поздравлениями:
    isCongratsModalOpen: false,
    // для отображения результата взаимодействия html и css:
    resultView: { __html: "" },
    // время старта для таймера:
    timerEndTime: Date.now() + oneMinuteInMs * 12,
    // показываем ли таймер на текущем вопросе:
    showTimer: true,
    // показать компонент ошибки (не корректная ссылка или блок в query parametrs строки или юзер не участник марафона):
    errorShow: false,
    // показать компонент ошибки когда токен или блок не валиден:
    errorInvalidTokenOrBlock: false,
    // для фиксации времени прохождения пользователем текущего вопроса:
    startTime: null,
    showBrowserResultModal: false,
    // буль на случай когда лежит сервер
    isServerError: false,
    // для модальных окон после 5ти дней о том есть ли нерешенные задания в каких то днях или марафон полностью завершен
    // nonFinishedDays: null, - по другому открываем модалку финишную, не нужно в автопроверке
    // finishedLastDay: false, - по другому открываем модалку финишную, не нужно в автопроверке
    // passedAllTasks: false, - по другому открываем модалку финишную, не нужно в автопроверке

    // лоадер
    showLoader: false,

    // для быстрой проверки кода:
    fastFailedConditions: [],
    fastSuccessConditions: [],

    // для подсветки или отключения конкретных строчек в редакторе кода
    notEditableHtmlBlocks: [],
    notEditableCssBlocks: [],
    htmlHlLines: [],
    cssHlLines: [],

    // для модального окна с результатами блока:
    showModalBlockResult: false,
    // countShowModalBlockResultOnThisQuestion: 0,
    isRefreshCurrentQuestion: false,

    htmlCursor: null,
    cssCursor: null,

    progress: 0,
    passedTasks: [],
    isShowErrorServerRefreshStub: false,
    showModalAutotranslator: false,
  };

  theoryAndTaskRef = createRef();
  // codeMirrorHTMLRef = createRef();
  // codeMirrorCSSRef = createRef();

  headerTextLogoRef = createRef();
  headerTextQuestionRef = createRef();
  footerTextRef = createRef();

  componentDidMount() {
    const { location } = this.props;
    const { clientWidth, clientHeight } = this.props;
    if (location.search && clientWidth > 900 && clientHeight > 600) {
      const token = new URLSearchParams(location.search).get("token");
      const block = new URLSearchParams(location.search).get("block");
      this.getCurrentBlockAndCurrentTask(token, block);
    } else {
      this.setState({ errorShow: true });
    }

    // checkAvailabilityAutotranslator logic - start
    timerAvailabilityAutotranslatorId = setInterval(() => {
      if (shouldAvailabilityAutotranslator) {
        availabilityAutotranslatorTimer -= 100;

        if (availabilityAutotranslatorTimer <= 0) {
          shouldAvailabilityAutotranslator = false;
          availabilityAutotranslatorTimer = waitTimeInMs;

          // monitoring availability autotranslator:
          this.checkAvailabilityAutotranslator();
        }
      }
    }, 100);
    // checkAvailabilityAutotranslator logic - end

    // monitoring availability autotranslator - start
    setTimeout(() => {
      this.checkAvailabilityAutotranslator();
    }, 2000);
    // monitoring availability autotranslator - end
  }

  componentDidUpdate(prevProps, prevState) {
    const { valueHTML, valueCSS, currentTaskId } = this.state;
    const { clientWidth, clientHeight } = this.props;
    // checkAvailabilityAutotranslator logic - start
    if (
      (prevState.valueHTML !== valueHTML &&
        clientWidth > 900 &&
        clientHeight > 600) ||
      (prevState.currentTaskId !== currentTaskId &&
        clientWidth > 900 &&
        clientHeight > 600) ||
      (prevState.valueCSS !== valueCSS &&
        clientWidth > 900 &&
        clientHeight > 600)
    ) {
      shouldAvailabilityAutotranslator = true;
      availabilityAutotranslatorTimer = waitTimeInMs;
    }
    // checkAvailabilityAutotranslator logic - end

    if (
      (prevState.valueCSS !== valueCSS &&
        clientWidth > 900 &&
        clientHeight > 600) ||
      (prevState.valueHTML !== valueHTML &&
        clientWidth > 900 &&
        clientHeight > 600)
    ) {
      this.setState(
        {
          resultView: { __html: valueHTML },
        },
        () => {
          this.addEventPreventDefaultForLinkInResultContainer();

          //Should make fast check
          shouldFastCheckTask = true;

          setTimeout(() => {
            if (shouldFastCheckTask) {
              this.fastCheckTest();

              shouldFastCheckTask = false;
            }
          }, 500);
        }
      );
    }

    const { blockTasksArr } = this.state;
    if (
      (prevState.clientWidth !== clientWidth &&
        prevState.clientWidth < 900 &&
        blockTasksArr === "") ||
      (prevState.clientHeight !== clientHeight &&
        prevState.clientHeight < 600 &&
        blockTasksArr === "")
    ) {
      const { location } = this.props;
      if (location.search) {
        const token = new URLSearchParams(location.search).get("token");
        const block = new URLSearchParams(location.search).get("block");
        this.getCurrentBlockAndCurrentTask(token, block);
      } else {
        this.setState({ errorShow: true });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(timerAvailabilityAutotranslatorId);
  }

  hideTimer = () => {
    this.setState({ showTimer: false });
  };
  forHighligthExampleInTheoryAndTaskSection = () => {
    document.querySelectorAll("pre code").forEach((block) => {
      window.hljs.highlightBlock(block);
    });
  };
  handleChangeHMTL = (value) => {
    this.setState({ valueHTML: value });
    shouldFastCheckTask = true;
  };
  handleChangeCSS = (value) => {
    this.setState({ valueCSS: value });
    shouldFastCheckTask = true;
  };

  getCurrentBlockAndCurrentTask = (token, block) => {
    this.setState({ showLoader: true });
    API.getTasksBlockAndCurrentTask(token, block)
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              currentTaskId: res.data.currentTask,
              blockTasksArr: res.data.blockTasks,
              token,
              block,
              blockIndex: res.data.blockIndex,
              passedTasks: res.data.passedTasks,

              errorInvalidTokenOrBlock: false,
              errorShow: false,
              isServerError: false,
            },
            () => {
              this.getTask();
            }
          );
        } else {
          this.setState({ errorInvalidTokenOrBlock: true });
          toast.error(res.data.message, {
            autoClose: 4000,
          });
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  getTask = () => {
    const { token, currentTaskId } = this.state;
    toast.dismiss();

    this.resetTaskCriteriaConditions();
    this.setState((prevState) => ({
      passedTasks: prevState.passedTasks.includes(currentTaskId)
        ? [...prevState.passedTasks]
        : [...prevState.passedTasks, currentTaskId],
      showLoader: true
    }));
    API.getTaskInfo(token, currentTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              startTime: Date.now(),
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  checkTest = () => {
    const { token, currentTaskId, valueHTML, valueCSS, startTime } = this.state;
    const solveTimeSeconds = Math.round((Date.now() - startTime) / 1000);

    API.checkTest(token, currentTaskId, valueHTML, valueCSS, solveTimeSeconds)
      .then((res) => {
        this.setState({
          successConditions: res.data.successConditions,
          failedConditions: res.data.failedConditions,
          taskSuccessfullyPassed: res.data.taskSuccessfullyPassed,
          isServerError: false,
        }, () => {
          const { failedConditions, currentTaskId, passedTasks } = this.state;
          if (!passedTasks.includes(currentTaskId) && failedConditions.length === 0) {
            this.setState((prevState) => ({
              passedTasks: [...prevState.passedTasks, currentTaskId]
            }));
          }
        });
      })
      .catch(() => {
        this.setState({ isServerError: true });
      });
  };

  fastCheckTest = () => {
    const { token, currentTaskId, valueHTML, valueCSS, startTime } = this.state;
    const solveTimeSeconds = Math.round((Date.now() - startTime) / 1000);
    API.fastCheckTest(
      token,
      currentTaskId,
      valueHTML,
      valueCSS,
      solveTimeSeconds
    )
      .then((res) => {
        this.setState({
          fastFailedConditions: (res.data && res.data.failedConditions) || [],
          fastSuccessConditions: (res.data && res.data.successConditions) || [],
          isServerError: false,
        });
      })
      .catch(() => {
        this.setState({ isServerError: true });
      });
  };

  closeResultModal = () => this.setState({ isResultModalOpen: false });

  openCongratsModal = () => this.setState({ isCongratsModalOpen: true });
  closeCongratsModal = () => this.setState({ isCongratsModalOpen: false });

  getNextTask = () => {
    toast.dismiss();
    const { token, currentTaskId, blockTasksArr } = this.state;
    const nextTaskId =
      blockTasksArr.indexOf(currentTaskId) !== blockTasksArr.length - 1
        ? blockTasksArr[blockTasksArr.indexOf(currentTaskId) + 1]
        : blockTasksArr[0];

    this.resetTaskCriteriaConditions();
    this.setState((prevState) => ({
      showLoader: true,
      passedTasks: prevState.passedTasks.includes(currentTaskId)
        ? [...prevState.passedTasks]
        : [...prevState.passedTasks, currentTaskId],
    }));
    API.getTaskInfo(token, nextTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              currentTaskId: res.data.taskId,
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + oneMinuteInMs * 12,
              showTimer: true,
              startTime: Date.now(),
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  getPrevTask = () => {
    toast.dismiss();

    const { token, currentTaskId, blockTasksArr } = this.state;
    const nextTaskId =
      blockTasksArr.indexOf(currentTaskId) !== 1
        ? blockTasksArr[blockTasksArr.indexOf(currentTaskId) - 1]
        : blockTasksArr[0];

    this.resetTaskCriteriaConditions();
    this.setState({ showLoader: true });
    API.getTaskInfo(token, nextTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              currentTaskId: res.data.taskId,
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + oneMinuteInMs * 12,
              showTimer: true,
              startTime: Date.now(),
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  toggleTheoryAndTask = () => {
    this.setState(
      (prevState) => ({
        isTheoryAndTaskShow: !prevState.isTheoryAndTaskShow,
      }),
      () => this.forHighligthExampleInTheoryAndTaskSection()
    );
  };

  callToastify = () => {
    const { passed, metadata } = this.state;
    if (passed) {
      toast.success("Эта задача уже решена!", {
        autoClose: 4000,
      });
    }
    if (metadata.disableHtmlEditor) {
      toast.warn(
        "В этом задании HTML редактор не активен, используй только CSS",
        {
          autoClose: 8000,
        }
      );
    }
    if (metadata.disableCssEditor && metadata.needCss) {
      toast.warn(
        "В этом задании CSS редактор не активен, используй только HTML",
        {
          autoClose: 8000,
        }
      );
    }
  };

  refreshCurrentQuestion = () => {
    const { token, currentTaskId } = this.state;
    toast.dismiss();

    this.resetTaskCriteriaConditions();
    this.setState({ showLoader: true });
    API.getTaskInfo(token, currentTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.setState({ isRefreshCurrentQuestion: true });
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .then(() => {
        this.setState({ isRefreshCurrentQuestion: false });
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  openBrowserResultModal = () => {
    this.setState({ showBrowserResultModal: true });
  };

  clodeBrowserResultModal = () => {
    this.setState({ showBrowserResultModal: false });
  };

  changeBackgroundInCodemirrorWithHtml = () => {
    const { metadata } = this.state;
    if (metadata && metadata.disableHtmlEditor) {
      // console.log("#html_box work disable");
      if (!document.querySelector("#html_box")) return;
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 0.9)";
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 0.9)";
    } else if (metadata && !metadata.disableHtmlEditor) {
      // console.log("#html_box work active");
      if (!document.querySelector("#html_box")) return;
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
    }

    if (metadata && metadata.disableCssEditor) {
      // console.log("#css_box work disable");
      if (!document.querySelector("#css_box")) return;
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 0.9)";
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 0.9)";
    } else if (metadata && !metadata.disableCssEditor) {
      // console.log("#css_box work active");
      if (!document.querySelector("#css_box")) return;
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
    }
  };

  addEventPreventDefaultForLinkInResultContainer = () => {
    const { currentTaskId } = this.state;
    // console.log("currentTaskId ", currentTaskId);
    if (currentTaskId === "css-36" || currentTaskId === "css-netlify") return;
    document
      .querySelector("iframe")
      .contentDocument.querySelectorAll("a")
      .forEach((el) =>
        el.addEventListener("click", function (e) {
          e.preventDefault();
          return false;
        })
      );
  };

  getTotalProgress = () => {
    const { token, block } = this.state;
    if (block !== null && token) {
      API.getTotalBlocksProgress(token, block)
        .then((res) => {
          this.setState({
            progress: res.data["645hg4y7"].progress,
            isServerError: false,
          });
        })
        .catch(() => {
          this.setState({ isServerError: true });
        });
    }
  };

  openModalBlockResultAndChangeCount = () => {
    this.setState({
      showModalBlockResult: true,
      countShowModalBlockResultOnThisQuestion: 1,
    });
  };
  closeModalBlockResult = () => {
    this.setState({ showModalBlockResult: false });
  };

  closeModalFinish = () => {
    this.setState({ progress: 0 });
  };

  getSpecificTask = (taskId) => {
    toast.dismiss();
    const { token } = this.state;

    this.resetTaskCriteriaConditions();
    this.setState({ showLoader: true });
    API.getTaskInfo(token, taskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              currentTaskId: res.data.taskId,
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              showTimer: true,
              isServerError: false,
              notEditableCodeBlocks: res.data.notEditableJsBlocks,
              codeHlLines: res.data.codeHlLines,
              codeCursor: res.data.codeCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  resetTaskCriteriaConditions = () => {
    this.setState({
      successConditions: [],
      failedConditions: [],
      taskSuccessfullyPassed: false,
    });
  };

  checkAvailabilityAutotranslator = () => {
    if (
      !this.headerTextLogoRef.current ||
      !this.headerTextQuestionRef.current ||
      !this.footerTextRef.current
    )
      return;

    if (
      !this.headerTextLogoRef.current.textContent.includes(
        "Пре-курс HTML/CSS"
      ) ||
      !this.headerTextQuestionRef.current.textContent.includes("Задача") ||
      !this.footerTextRef.current.textContent.includes(
        "Политика конфиденциальности"
      )
    ) {
      this.openModalAutotranslator();
    }
  };
  openModalAutotranslator = () => {
    this.setState({ showModalAutotranslator: true });
  };
  closeModalAutotranslator = () => {
    this.setState({ showModalAutotranslator: false });
  };

  render() {
    // if the server reboots, you need to wait 1-2 minutes and reload the page:
    const { isShowErrorServerRefreshStub } = this.state;
    if (isShowErrorServerRefreshStub) {
      return <ErrorServerRefreshStub />;
    }

    // если не работает сервер, заглушка
    const { isServerError } = this.state;
    if (isServerError) {
      return <ErrorServerStub />;
    }

    // если не валиден token или block:
    const { errorInvalidTokenOrBlock } = this.state;
    if (errorInvalidTokenOrBlock) {
      return (
        <>
          <ToastContainer />
          <ErrorInvalidTokenOrBlock />
        </>
      );
    }

    // Если в url нету query parameters:
    const { errorShow } = this.state;
    if (errorShow) {
      return <ErrorStub />;
    }

    //  меняем беграунд html редактора когда он должен быть неактивным на редактирование
    const { metadata } = this.state;
    const { clientWidth, clientHeight } = this.props;
    if (
      metadata &&
      (metadata.disableHtmlEditor || metadata.disableCssEditor) &&
      clientWidth > 900 &&
      clientHeight > 600
    ) {
      this.changeBackgroundInCodemirrorWithHtml();
    }

    const {
      valueHTML,
      valueCSS,
      blockTasksArr,
      currentTaskId,
      // для модального окна с результатами:
      isResultModalOpen,
      successConditions,
      failedConditions,
      taskSuccessfullyPassed,
      passed,
      // isTheoryAndTaskShow
      isTheoryAndTaskShow,
      isCongratsModalOpen,
      resultView,
      // время для таймера:
      timerEndTime,
      showTimer,
      // номер дня марафона в хедере
      blockIndex,
      showBrowserResultModal,
      // nonFinishedDays,
      // finishedLastDay, - по другому открываем модалку финишную, не нужно в автопроверке
      // passedAllTasks, - по другому открываем модалку финишную, не нужно в автопроверке
      showLoader,
      htmlDescription,
      fastFailedConditions,
      fastSuccessConditions,
      showModalBlockResult,

      notEditableHtmlBlocks,
      notEditableCssBlocks,
      htmlHlLines,
      cssHlLines,

      isRefreshCurrentQuestion,
      htmlCursor,
      cssCursor,
      progress,
      token,
      passedTasks,
      showModalAutotranslator,
    } = this.state;

    const widthCodeAndResultSection = isTheoryAndTaskShow
      ? metadata.needCss
        ? "53%"
        : "48%"
      : "94%";
    const widthTheoryAndTaskSection = metadata.needCss ? "45%" : "50%";
    const marginLeftCodeAndResultSection = isTheoryAndTaskShow ? "2%" : "6%";

    // const widthHtmlBox = !metadata.needCss ? "100%" : "59%";
    // const widthCssBox = "40%";

    let percentageDoneTask = 0;
    const fastSuccessConditionsLength = fastSuccessConditions.length;
    const fastFailedConditionsLength = fastFailedConditions.length;
    if (fastSuccessConditionsLength || fastFailedConditionsLength) {
      percentageDoneTask = Number(
        (
          (fastSuccessConditions.length * 100) /
          (fastSuccessConditions.length + fastFailedConditions.length)
        ).toFixed(0)
      );
    }

    return (
      <>
        <Header
          blockTasksArr={blockTasksArr}
          currentTaskId={currentTaskId}
          getPrevTask={this.getPrevTask}
          getNextTask={this.getNextTask}
          passed={passed}
          blockIndex={blockIndex}
          passedTasks={passedTasks}
          getSpecificTask={this.getSpecificTask}
          headerTextLogoRef={this.headerTextLogoRef}
          headerTextQuestionRef={this.headerTextQuestionRef}
        />
        <main className={styles.main}>
          <article className={styles.mainContent}>
            <ButtonHideTheory
              toggleTheoryAndTask={this.toggleTheoryAndTask}
              isTheoryAndTaskShow={isTheoryAndTaskShow}
            />

            {/* начало секции теории и задания */}
            {isTheoryAndTaskShow && (
                <section
                  ref={this.theoryAndTaskRef}
                  style={{ width: widthTheoryAndTaskSection }}
                >
                  <TheoryAndTaskOrLayout
                    isServerError={isServerError}
                    isTheoryAndTaskShow={isTheoryAndTaskShow}
                    htmlDescription={htmlDescription}
                    cvLink={metadata.cvLink}
                  />
                </section>
            )}
            {/* конец секции теории и задания */}

            {/* начало секции кода и результата */}
            <section
              className={styles.codeAndResult_container}
              style={{
                width: widthCodeAndResultSection,
                marginLeft: marginLeftCodeAndResultSection,
              }}
            >
              <div className={styles.checkBoxWrapper}>
                <h4 className={styles.checkTitle}>Результаты:</h4>
                <div className={styles.criteria}>
                  <ol>
                    {fastFailedConditions.map((element) => (
                      <li className={styles.wrongCriteria} key={element}>
                        <NotDone className={styles.criteriaIcon} />{" "}
                        <span>{element}</span>
                      </li>
                    ))}
                  </ol>
                  <ol>
                    {fastSuccessConditions.map((element) => (
                      <li className={styles.rightCriteria} key={element}>
                        <Done className={styles.criteriaIcon} />{" "}
                        <span>{element}</span>
                      </li>
                    ))}
                  </ol>
                </div>

                <div className={styles.percentage}>
                  <CircularProgressbar
                    className={styles.percentage}
                    value={percentageDoneTask}
                    text={`${fastSuccessConditionsLength} / ${
                      fastFailedConditionsLength + fastSuccessConditionsLength
                    }`}
                    styles={buildStyles({
                      // Colors
                      pathColor: "#169b20",
                      textColor: "#169b20",
                      // trailColor: "#d6d6d6",
                      // backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>

              <div className={styles.code_container}>
                <div className={styles.box}>
                  <h4 className={styles.codeTitle}>index.html:</h4>
                  <div className={styles.containerHTML} id="html_box">
                    <CustomCodeMirror
                      mode="html"
                      code={valueHTML}
                      onChangeCode={this.handleChangeHMTL}
                      notEditableBlocks={notEditableHtmlBlocks}
                      hlLines={htmlHlLines}
                      propertyReadOnly={metadata && metadata.disableHtmlEditor}
                      currentTaskId={currentTaskId}
                      isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                      positionCursor={htmlCursor}
                    />
                  </div>
                </div>

                {metadata.needCss && (
                  <ResizableWrapper>
                    <div className={styles.boxCss}>
                      <h4 className={styles.codeTitle}>styles.css:</h4>
                      <div className={styles.containerCSS} id="css_box">
                        <CustomCodeMirror
                          mode="css"
                          code={valueCSS}
                          onChangeCode={this.handleChangeCSS}
                          notEditableBlocks={notEditableCssBlocks}
                          hlLines={cssHlLines}
                          propertyReadOnly={
                            metadata && metadata.disableCssEditor
                          }
                          currentTaskId={currentTaskId}
                          isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                          positionCursor={cssCursor}
                        />
                      </div>
                    </div>
                  </ResizableWrapper>
                )}
              </div>

              <TaskButtons
                checkTest={this.checkTest}
                refreshCurrentQuestion={this.refreshCurrentQuestion}
                timerEndTime={timerEndTime}
                metadata={metadata}
                // для анимации первого дня первого вопроса
                blockIndex={blockIndex}
                currentTaskId={currentTaskId}
                blockTasksArr={blockTasksArr}
                showTimer={showTimer}
                hideTimer={this.hideTimer}
                clientWidth={clientWidth}
                isTheoryAndTaskShow={isTheoryAndTaskShow}
                percentageDoneTask={percentageDoneTask}
                getNextTask={this.getNextTask}
                getTotalProgress={this.getTotalProgress}
                openModalBlockResultAndChangeCount={
                  this.openModalBlockResultAndChangeCount
                }
                showModalBlockResult={showModalBlockResult}
              />

              {/* <h4 className={styles.result_title}>Результат:</h4> */}
              <div ref={this.iframeRef} className={styles.result_container}>
                <BrowserHeader
                  openBrowserResultModal={this.openBrowserResultModal}
                  refreshCurrentQuestion={this.refreshCurrentQuestion}
                />
                <FrameComponent>
                  <div>
                    <style>{valueCSS}</style>
                    <div dangerouslySetInnerHTML={resultView} />
                  </div>
                </FrameComponent>
              </div>
            </section>
            {/* конец секции кода и результата */}
          </article>
        </main>
        <Footer footerTextRef={this.footerTextRef} />
        {isResultModalOpen && (
          <ResultModal
            onClose={this.closeResultModal}
            successConditions={successConditions}
            failedConditions={failedConditions}
            taskSuccessfullyPassed={taskSuccessfullyPassed}
            getTask={this.getTask}
            getNextTask={this.getNextTask}
            openCongratsModal={this.openCongratsModal}
            blockTasksArr={blockTasksArr}
            currentTaskId={currentTaskId}
          />
        )}
        {isCongratsModalOpen && (
          <CongratsModal
            onClose={this.closeCongratsModal}
            blockTasksArr={blockTasksArr}
            blockIndex={blockIndex}
            getTotalProgress={this.getTotalProgress}
          />
        )}
        {showBrowserResultModal && (
          <BrowserResultModal
            onClose={this.clodeBrowserResultModal}
            refreshCurrentQuestion={this.refreshCurrentQuestion}
            valueCSS={valueCSS}
            resultView={resultView}
          />
        )}
        <ToastContainer />

        {/* {finishedLastDay && !passedAllTasks && (
          <ModalUnresolvedDays nonFinishedDays={nonFinishedDays} />
        )} */}

        {showLoader && <Loader />}

        {showModalBlockResult && (
          <ModalBlockResult
            onClose={this.closeModalBlockResult}
            blockTasksArr={blockTasksArr}
            currentTaskId={currentTaskId}
          />
        )}
        {progress === 100 && (
          <ModalFinish token={token} closeModal={this.closeModalFinish} />
        )}
        {/* <ModalFinish token={token} closeModal={this.closeModalFinish} /> */}

        {showModalAutotranslator && (
          <ModalAutotranslator onClose={this.closeModalAutotranslator} />
        )}
      </>
    );
  }
}

export default MainPage;
