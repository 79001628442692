import React, { Component } from "react";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import { isPossiblePhoneNumber } from "react-phone-number-input";
import * as API from "../../services/api";
import shortid from "shortid";
import s from "./ModalFinish.module.css";

class ModalFinish extends Component {
  state = {
    firstName: "",
    lastName: "",
    phone: "",
    tryAgainRequest: false,
    tryAgainEnterPhoneNumber: false,
    isRegisterInTelegramBot: true,
  };

  componentDidMount() {
    const Confettiful = function (el) {
      this.el = el;
      this.containerEl = null;

      this.confettiFrequency = 3;
      this.confettiColors = ["#fce18a", "#ff726d", "#b48def", "#f4306d"];
      this.confettiAnimations = ["slow", "medium", "fast"];

      this._setupElements();
      this._renderConfetti();
    };

    Confettiful.prototype._setupElements = function () {
      const containerEl = document.createElement("div");
      const elPosition = this.el.style.position;

      if (elPosition !== "relative" || elPosition !== "absolute") {
        this.el.style.position = "relative";
      }

      containerEl.classList.add("confetti-container");

      this.el.appendChild(containerEl);

      this.containerEl = containerEl;
    };

    Confettiful.prototype._renderConfetti = function () {
      this.confettiInterval = setInterval(() => {
        const confettiEl = document.createElement("div");
        const confettiSize = Math.floor(Math.random() * 3) + 7 + "px";
        const confettiBackground = this.confettiColors[
          Math.floor(Math.random() * this.confettiColors.length)
        ];
        const confettiLeft =
          Math.floor(Math.random() * this.el.offsetWidth) + "px";
        const confettiAnimation = this.confettiAnimations[
          Math.floor(Math.random() * this.confettiAnimations.length)
        ];

        confettiEl.classList.add(
          "confetti",
          "confetti--animation-" + confettiAnimation
        );
        confettiEl.style.left = confettiLeft;
        confettiEl.style.width = confettiSize;
        confettiEl.style.height = confettiSize;
        confettiEl.style.backgroundColor = confettiBackground;

        confettiEl.removeTimeout = setTimeout(function () {
          confettiEl.parentNode.removeChild(confettiEl);
        }, 3000);

        this.containerEl.appendChild(confettiEl);
      }, 25);

      // document.querySelector("#js-container").style.position = "fixed";
      document.querySelector("body").classList.add("noscroll");
    };

    window.confettiful = new Confettiful(
      document.querySelector("#js-container")
    );

    document.querySelector("#js-container").style.position = "fixed";
  }

  componentWillUnmount() {
    document.querySelector("body").classList.remove("noscroll");
  }

  inputIds = {
    firstNameId: shortid.generate(),
    lastNameId: shortid.generate(),
    phoneId: shortid.generate(),
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { token, closeModal } = this.props;
    if (token) {
      const { firstName, lastName, phone } = this.state;
      if (this.isPossiblePhoneNumber(phone)) {
        API.requestResumeCertificate(token, firstName, lastName, phone)
          .then((res) => {
            this.setState({
              tryAgainRequest: false,
              tryAgainEnterPhoneNumber: false,
            });
            if (res.data.success) {
              this.resetForm();
              closeModal();
              this.setState({ isRegisterInTelegramBot: true });
            } else {
              this.setState({ isRegisterInTelegramBot: false });
            }
          })
          .catch(() => {
            this.setState({
              tryAgainRequest: true,
              tryAgainEnterPhoneNumber: false,
            });
          });
      } else {
        this.setState({ tryAgainEnterPhoneNumber: true });
      }
    }
  };

  isPossiblePhoneNumber = (string) => {
    if (string.length < 10) {
      return false;
    }
    const arrSigns = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      " ",
      "-",
      "+",
    ];
    const arrFromString = string.split("");
    // проверка на другие символы кроме тех что есть в массиве arrNumbers
    let countNumbers = 0;
    for (let i = 0; i < arrFromString.length; i++) {
      if (!arrSigns.includes(arrFromString[i])) {
        return false;
      }
      // проверка на то что нету минимум 9ти циф
      if (
        arrFromString[i] !== " " &&
        arrFromString[i] !== "-" &&
        arrFromString[i] !== "+"
      ) {
        countNumbers += 1;
      }
    }

    if (countNumbers < 10) {
      return false;
    }

    return true;
  };

  resetForm = () => {
    this.setState({
      firstName: "",
      lastName: "",
      phone: "",
    });
  };

  render() {
    const { firstNameId, lastNameId, phoneId } = this.inputIds;
    const {
      firstName,
      lastName,
      phone,
      tryAgainRequest,
      tryAgainEnterPhoneNumber,
      isRegisterInTelegramBot,
    } = this.state;

    return (
      <article className={s.wrapper} id="js-container">
        <div className={s.contentWrapper}>
          <h2 className={s.modalTitle}>ПОЗДРАВЛЯЕМ!</h2>

          <div className={s.textWrapper}>
            <p className={s.text}>Твои задания выполнены на 100%.</p>
            <p className={s.text}>
              Всего выполнено 44 задания. Отличный результат!
            </p>
            <p className={s.text}>
              Результаты своей работы можешь оценить{" "}
              <a
                href="https://gofrontend.com.ua/result"
                target="_blank"
                rel="noopener noreferrer"
              >
                по ссылке тут.
              </a>
            </p>
            <p className={s.text}>
              Напишите свои данные для того, чтобы получить сертификат о
              прохождении пре-курса HTML/CSS:
            </p>
            <form className={s.form} onSubmit={this.handleSubmit}>
              <input
                required
                name="firstName"
                onChange={this.handleChange}
                value={firstName}
                id={firstNameId}
                className={s.input}
                placeholder="Имя"
                type="text"
              />
              <input
                required
                name="lastName"
                onChange={this.handleChange}
                value={lastName}
                id={lastNameId}
                className={s.input}
                placeholder="Фамилия"
                type="text"
              />
              <input
                required
                name="phone"
                onChange={this.handleChange}
                value={phone}
                id={phoneId}
                className={s.input}
                placeholder="Номер мобильного телефона"
                type="tel"
              />
              {/* <PhoneInput
                onChange={(phone) => this.setState({ phone })}
                value={phone}
                className={s.phoneInput}
                placeholder="Номер мобильного телефона"
              /> */}
              <button className={s.button} type="submit">
                Отправить
              </button>
              {tryAgainEnterPhoneNumber && (
                <p className={s.error}>Введите корректный номер телефона</p>
              )}
              {tryAgainRequest && (
                <p className={s.error}>
                  Произошла ошибка на сервере, попробуйте снова позже.
                </p>
              )}
              {!isRegisterInTelegramBot && (
                <p className={s.error}>
                  Пользователь не зарегистрирован в telegram-боте.
                </p>
              )}
            </form>
          </div>
        </div>
      </article>
    );
  }
}

export default ModalFinish;
